
import { getTableDetail, setCommissionTable } from '@/api'
import { useCountdown } from '@/composables/useCountDown'
import { ROUTES } from '@/router'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import { generateRandomNumber, getDeviceOrientation } from '@/utils'
import {
    PropType,
    TableDetails,
    computed,
    defineComponent,
    onMounted,
    ref,
    ResponseData,
    Emitter,
    inject,
    watch,
    SocketMessage,
    onBeforeUnmount,
    onBeforeMount
} from 'vue'

import {
    IconNotice,
    SvgDolar,
    SvgFullScreenToggle,
    SvgSetting,
    IconStatistics,
    IconAllRoad,
    IconBigRoad,
    IconThumbsUp4
} from '@/components/icons'
import { useI18n } from 'vue-i18n'
import { VTextMarquee } from 'vue-text-marquee'
import { useRouter } from 'vue-router'
import GameChipControl from '@/components/custom-controls/game-chip-control-v2.vue'
import { NuiData } from '@/types/Nui'
import RoadMap from '@/components/roadmap/index.vue'
import CountDownTimer from '@/components/timer-count-down.vue'
import PcNuiBetGroupV1 from '@/components/bet/pc-nui-bet-group-v1.vue'
import MobileNuiBetGroupV1 from '@/components/bet/mobile-nui-bet-group-v1.vue'
import MobileChipControlH from '@/components/mobile-chip-control-horizontal.vue'

export default defineComponent({
    emits: ['show-dialog', 'show-switch-desk'],
    props: {
        desk: {
            default: -1,
            type: Number as PropType<number>
        },
        hall: {
            type: Number as PropType<number>,
            default: 1
        }
    },
    components: {
        'marquee-text': VTextMarquee,
        'icon-notice': IconNotice,
        'svg-dolar': SvgDolar,
        'svg-full-screen-toggle': SvgFullScreenToggle,
        'svg-setting': SvgSetting,
        'icon-thumbs-4': IconThumbsUp4,
        'game-chip-control': GameChipControl,
        'road-map': RoadMap,
        'timer': CountDownTimer,
        'pc-nui-bet-group-v1': PcNuiBetGroupV1,
        'mobile-nui-bet-group-v1': MobileNuiBetGroupV1,
        'm-chip-control-h': MobileChipControlH
    },
    setup(props) {
        const emitter = inject('emitter') as Emitter
        const { t } = useI18n()
        const store = useStore()
        const router = useRouter()
        const deskVideoOn = ref<boolean>(false)
        const chip = ref()
        const videoBg = ref(generateRandomNumber(1, 8))
        const refBetGroup = ref<any>()
        const refMobileBetGroup = ref<any>()
        const betViewKey = ref(`dbetview${new Date().getTime()}`)
        const winResult = ref<number[]>([])
        const hasTempBet = ref(false)
        const nuiData = ref(new NuiData([], 8))
        const showExtraLimit = ref(true)

        const toastMessage = ref<
            { id: number | boolean | any; message: string }[]
        >([])

        const deskData = ref<TableDetails | undefined>(undefined)
        const deskNotice = ref<string>('')
        const deskNoticeKey = ref(`notice${new Date().getTime()}`)

        const p1MapKey = ref(`p1MapKey${new Date().getTime()}`)
        const p2MapKey = ref(`p2MapKey${new Date().getTime()}`)
        const p3MapKey = ref(`p3MapKey${new Date().getTime()}`)

        const enableOpenPoker = ref<boolean>(false)

        let videoPlayerCollection: Array<any> = new Array<any>()
        let nodePlayer!: any
        const isLoadingVideo = ref<boolean>(true)

        let loadDataFailedCounter = 0

        const { currentCount, startCountDown, stopCountDown } = useCountdown(
            true,
            () => onCountDownStop()
        )

        //#region Vue Helpers

        onMounted(() => {
            deskVideoOn.value = videoIsOn.value
            removeallToast()
        })

        onBeforeMount(() => {
            emitter.on(EventBuss.SMALL_TOAST, handleSmallToastMessage)
            emitter.on(EventBuss.SOCKET_MESSAGE, handleSocketMessage)
            emitter.on(EventBuss.WINDOW_RESIZED, handleOnWindowResize)
        })

        onBeforeUnmount(() => {
            stopCountDown()
            disposeVideoPlayer()
            removeallToast()

            emitter.off(EventBuss.SMALL_TOAST, handleSmallToastMessage)
            emitter.off(EventBuss.SOCKET_MESSAGE, handleSocketMessage)
            emitter.off(EventBuss.WINDOW_RESIZED, handleOnWindowResize)
        })

        //#endregion

        //#region Websocket Helpers
        const handleSocketMessage = (_message: SocketMessage | any) => {
            //
        }
        //#endregion

        //#region Helpers

        const handleOnWindowResize = () => {
            p1MapKey.value = `p1MapKey${new Date().getTime()}`
            p2MapKey.value = `p2MapKey${new Date().getTime()}`
            p3MapKey.value = `p3MapKey${new Date().getTime()}`
        }

        const handleSmallToastMessage = (message: {
            msg: string
            id: number
        }) => {
            if (deskData.value && deskData.value.gameTable.id === message.id) {
                const _t = {
                    id: setTimeout(() => {
                        const _tt = toastMessage.value.shift()
                        if (_tt && typeof _tt.id === 'number') {
                            clearTimeout(_tt.id)
                        }
                    }, 1500),
                    message: message.msg
                }
                toastMessage.value.push(_t)
            }
        }

        const removeallToast = () => {
            toastMessage.value.forEach((toastM) => {
                if (typeof toastM.id === 'number') {
                    clearTimeout(toastM.id)
                    toastM.id = false
                }
            })

            toastMessage.value = []
        }

        const onCountDownStop = () => {
            cancelTempbet()
        }

        const handleBetgroupUpdate = (_hasTempBet: boolean) => {
            hasTempBet.value = _hasTempBet
        }

        //#endregion

        //#region Click Helpers

        const cancelTempbet = (isMobile = false) => {
            if (hasTempBet.value) {
                if (
                    refBetGroup.value &&
                    deviceOrientation.value === 'landscape'
                ) {
                    refBetGroup.value.resetTempBet()
                }

                if (
                    refMobileBetGroup.value &&
                    deviceOrientation.value === 'portrait'
                ) {
                    refMobileBetGroup.value.resetTempBet()
                }
            }
        }
        const confirmTempBet = (isMobile = false) => {
            if (deskData.value && deskData.value.gameTable.status === 1) {
                if (
                    refBetGroup.value &&
                    deviceOrientation.value === 'landscape'
                )
                    refBetGroup.value.confirmTempBet()

                if (
                    refMobileBetGroup.value &&
                    deviceOrientation.value === 'portrait'
                )
                    refMobileBetGroup.value.confirmTempBet()
            }
        }

        const requestFullScreen = () => {
            emitter.emit(EventBuss.REQUEST_FULL_SCREEN)
        }

        const backHistory = () => {
            // history.go(-1)
            router.push({ name: ROUTES.LOBY })
        }

        //#endregion

        //#region API Call Helpers
        const loadData = (_forceReload = false) => {
            if (props.desk <= 0) {
                router.push({ name: ROUTES.LOBY })
                return
            }

            const _params = {
                memberId: memberid.value,
                tableId: props.desk,
                token: token.value
            }

            if (!_forceReload) store.commit(AppMutation.SHOW_LOADING)
            getTableDetail(_params)
                .then((_response) => {
                    loadDataFailedCounter = 0
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)
                    const responseData =
                        _response.data as ResponseData<TableDetails>

                    if (responseData.status === 200) {
                        if (
                            responseData.data?.gameTable.gameTypeNo !== 'G001'
                        ) {
                            router.push({ name: ROUTES.LOBY })
                            return
                        }

                        const { noticeList, gameTable } = responseData.data!
                        let _deskNotice = ''
                        noticeList.forEach((_notice, index) => {
                            if (index === 0) _deskNotice = _notice.content
                            else
                                _deskNotice = `${_deskNotice},   ${_notice.content}`
                        })
                        deskNotice.value = _deskNotice
                        deskNoticeKey.value = `notice${new Date().getTime()}`

                        deskData.value = responseData.data!

                        store.commit(
                            AppMutation.SET_COIN,
                            deskData.value.gameTable.expand.amount
                        )

                        // roadMapData.value = parseRoadMap(
                        //     deskData.value.gameTable.expand.resultList
                        // )

                        if (refBetGroup.value || refMobileBetGroup.value) {
                            setTimeout(() => {
                                if (refBetGroup.value)
                                    refBetGroup.value.resetBetUI()
                                if (refMobileBetGroup.value)
                                    refMobileBetGroup.value.resetBetUI()

                                if (
                                    deskData.value &&
                                    deskData.value.gameTable.expand.betFormList
                                ) {
                                    if (deskData.value.gameTable.status === 1) {
                                        deskData.value.gameTable.expand.betFormList.forEach(
                                            (i) => {
                                                if (
                                                    i.memberId ===
                                                    memberid.value
                                                ) {
                                                    enableOpenPoker.value = true
                                                }
                                            }
                                        )
                                    }
                                }
                            }, 250)
                        }

                        if (deskVideoOn.value) initVideoPlayer()
                    } else if (responseData.status === 301) {
                        // token is expired
                        // reset
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description!
                        )
                        emitter.emit(EventBuss.SOCKET_CLOSE)
                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    } else {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description!
                        )
                    }
                })
                .catch((e) => {
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)

                    // emitter.emit(EventBuss.TOAST_MESSAGE, '网络错误')

                    loadDataFailedCounter++
                    if (loadDataFailedCounter > 3) {
                        emitter.emit(
                            EventBuss.SMALL_TOAST,

                            {
                                msg: '当前网络不佳，请重新尝试',
                                id: deskData.value?.gameTable.id || 0
                            }
                        )

                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    } else {
                        // reload after 1 second
                        setTimeout(() => {
                            loadData(true)
                        }, 1500)
                    }
                })
        }
        const setMemberCommission = () => {
            if (!deskCommissionStatus.value) return
            if (deskData.value && deskData.value.gameTable.expand.betFormList) {
                let totalBet = 0
                deskData.value.gameTable.expand.betFormList.forEach((i) => {
                    if (i.memberId === memberid.value) {
                        totalBet += i.amount
                    }
                })

                if (totalBet > 0) return
            }

            const _params = {
                memberId: memberid.value,
                tableId: deskData.value?.gameTable.id || 0,
                status: memberCommisionStatus.value ? -1 : 1,
                token: token.value
            }

            store.commit(AppMutation.SHOW_LOADING)

            setCommissionTable(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (response.data.status === 100) {
                        emitter.emit(EventBuss.SMALL_TOAST, {
                            msg: response.data.description,
                            id: deskData.value?.gameTable.id || 0
                        })
                    } else if (response.data.status === 200) {
                        const _v = memberCommisionStatus.value ? -1 : 1
                        if (deskData.value) {
                            deskData.value.gameTable.expand.memberCommissionStatus =
                                _v
                        }
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(EventBuss.SMALL_TOAST, {
                        msg: '当前网络不佳，请重新尝试',
                        id: deskData.value?.gameTable.id || 0
                    })
                })
        }
        //#endregion

        //#region Video Player Helpers
        const initVideoPlayer = () => {
            isLoadingVideo.value = true
            disposeVideoPlayer()

            let isVideoConnected = false

            const videoUrl = deskData.value?.gameTable.cmccLine || ''

            console.log(videoUrl)

            // eslint-disable-next-line no-undef
            nodePlayer = new NodePlayer()
            nodePlayer.setVolume(0)

            if (getDeviceOrientation() === 'landscape') {
                nodePlayer.setView('videoplayer')
                // set scale mode to normal
                nodePlayer.setScaleMode(0)
            } else {
                nodePlayer.setView('m-videoplayer')
                nodePlayer.setScaleMode(2)
            }

            nodePlayer.setBufferTime(1000)

            nodePlayer.on('buffer', (evt: 'empty' | 'buffering' | 'full') => {
                if (evt === 'empty' || evt === 'buffering') {
                    isLoadingVideo.value = true
                } else if (evt === 'full') {
                    isLoadingVideo.value = false
                }
            })

            nodePlayer.on('error', () => {
                isLoadingVideo.value = true
                setTimeout(() => {
                    nodePlayer.clearView()
                    nodePlayer.stop()
                    initVideoPlayer()
                }, 1000)
            })

            nodePlayer.start(videoUrl)
        }
        const disposeVideoPlayer = () => {
            if (nodePlayer) {
                nodePlayer.stop()
                nodePlayer.clearView()
                nodePlayer = undefined
                isLoadingVideo.value = true
            }

            videoPlayerCollection.forEach((_player) => {
                if (_player) {
                    _player.clearView()
                    _player.stop()
                }
            })

            videoPlayerCollection = []
        }
        //#endregion

        //#region Computed

        const gameTableLimit = computed(() => {
            if (deskData.value) {
                return `彩池：${deskData.value.gameTable.downLimit}/${deskData.value.gameTable.upLimit}`
            } else {
                return '彩池：0/0'
            }
        })

        const gameTableDownLimit = computed(() => {
            if (deskData.value) {
                return deskData.value.gameTable.downLimit
            }
            return '0'
        })

        const gameTableUpLimit = computed(() => {
            if (deskData.value) {
                return deskData.value.gameTable.upLimit
            }
            return '0'
        })

        const deskCommissionStatus = computed(() => {
            if (deskData.value) {
                return deskData.value.gameTable.commissionStatus === 1
            }
            return false
        })
        const betRedCommision = computed(() => {
            if (deskCommissionStatus.value) {
                return memberCommisionStatus.value
            }

            return true
        })

        const memberCommisionStatus = computed(() => {
            if (deskData.value) {
                return (
                    deskData.value.gameTable.expand.memberCommissionStatus === 1
                )
            }
            return true
        })

        const maxTime = computed(() => {
            if (deskData.value) return deskData.value.gameTable.betTime
            return -1
        })

        const gameStatus = computed(() => {
            if (deskData.value) {
                return deskData.value.gameTable.status
            }

            return 0
        })

        const gameTable = computed(() => {
            if (deskData.value) return deskData.value.gameTable

            return undefined
        })
        const deviceOrientation = computed(
            (): 'portrait' | 'landscape' => store.getters['deviceOrientation']
        )
        const gameType = computed(() => {
            if (deskData.value)
                return deskData.value.gameTable.gameTypeNo.toUpperCase()

            return 'G002'
        })
        const mapData = computed(() => {
            if (deskData.value)
                return deskData.value.gameTable.expand.resultList
            return []
        })
        const tableName = computed(() => {
            if (deskData.value) {
                const { gameTypeNo, tableNo } = deskData.value.gameTable

                if (gameTypeNo.toUpperCase() === 'G002')
                    return `${t('baccarattable')} ${tableNo}`
                else if (gameTypeNo.toUpperCase() === 'G001') {
                    return `牛牛 ${tableNo}`
                } else return `${t('dragontigertable')} ${tableNo}`
            }
            return ''
        })
        const minAndMaxTableLimit = computed(() => {
            if (deskData.value) {
                const { downLimit, upLimit } = deskData.value.gameTable
                return `${downLimit}/${upLimit}`
            }
            return '0/0'
        })
        const gameid = computed(() => {
            if (deskData.value) {
                return deskData.value.gameTable.expand.gameNo
            }

            return '0'
        })
        const videoIsOn = computed(() => store.getters['videoIsOn'])
        const token = computed(() => store.getters['token'])
        const memberid = computed(() => store.getters['memberid'])
        const isfullScreen = computed(() => store.getters['isfullScreen'])
        const coin = computed(() => store.getters['coin'])
        const isClassic = computed(() => {
            return true
            // props.hall === 2
        })
        //#endregion

        //#region  Watchers
        watch(
            () => props.desk,
            (v) => {
                stopCountDown()

                // showCardDisplay.value = false

                betViewKey.value = `betview${new Date().getTime()}`

                winResult.value = []
                deskData.value = undefined
                // roadMapData.value = parseRoadMap([])

                loadData()
                videoBg.value = generateRandomNumber(1, 8)
            },
            { immediate: true }
        )

        watch(
            () => deskData.value,
            (v) => {
                if (v) {
                    nuiData.value = new NuiData(
                        v.gameTable.expand.resultList as Array<string>,
                        8
                    )
                }
            },
            { deep: true }
        )

        //#endregion

        return {
            videoBg,
            deskNoticeKey,
            toastMessage,
            chip,
            deskNotice,
            mapData,
            tableName,
            refBetGroup,
            betViewKey,
            refMobileBetGroup,
            gameType,
            hasTempBet,
            minAndMaxTableLimit,
            currentCount,
            gameTable,
            betRedCommision,
            winResult,
            isfullScreen,
            gameStatus,
            maxTime,
            gameid,
            coin,
            deskCommissionStatus,
            memberCommisionStatus,
            nuiData,
            p1MapKey,
            p2MapKey,
            p3MapKey,
            gameTableLimit,
            gameTableDownLimit,
            gameTableUpLimit,
            showExtraLimit,
            isClassic,
            confirmTempBet,
            cancelTempbet,
            requestFullScreen,
            handleBetgroupUpdate,
            backHistory,
            setMemberCommission
        }
    }
})
