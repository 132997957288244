
import { useStore } from '@/store'
import {
    ChipItem,
    computed,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    PropType,
    ref,
    watch
} from 'vue'
import { abbreviateNumber } from '@/utils'
import { useSmootScroll } from '@/composables/useSmootScroll'
import { dragscroll } from 'vue-dragscroll'
import { AppMutation } from '@/store/types'

export default defineComponent({
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: Object as PropType<ChipItem>
        }
    },
    directives: {
        dragscroll
    },
    setup(props, { emit }) {
        const store = useStore()
        const activeChipId = ref(-1)
        const scollDiv = ref<HTMLDivElement>()

        const { scrollContent } = useSmootScroll()

        onMounted(() => {
            if (chipDefault.value.length > 0) {
                if (userChip.value > 0) activeChipId.value = userChip.value
                else activeChipId.value = chipDefault.value[0].id
            }
        })

        const selectChip = (chipId: number) => {
            if (chipId !== activeChipId.value) {
                activeChipId.value = chipId
                store.commit(AppMutation.SET_CURRENT_CHIPS, chipId)
            }
        }

        const scroll = (direction: 'left' | 'right' | 'top' | 'bottom') => {
            scrollContent(direction, scollDiv.value)
        }

        const chipDefault = computed(
            (): ChipItem[] => store.getters['chipDefault']
        )

        const userChip = computed(() => store.getters['currentChip'])

        watch(
            () => activeChipId.value,
            (v) => {
                const chipValue = chipDefault.value.find((i) => i.id == v)
                if (chipValue) {
                    emit('update:modelValue', chipValue)
                }
            },
            { immediate: true }
        )

        watch(
            () => chipDefault.value,
            (v) => {
                setTimeout(() => {
                    const chipValue = chipDefault.value.find(
                        (i) => i.id == activeChipId.value
                    )
                    if (!chipValue && chipDefault.value.length > 0) {
                        activeChipId.value = chipDefault.value[0].id

                        // emit('update:modelValue', chipDefault.value[0])
                    }
                }, 100)
            }
        )

        return {
            chipDefault,
            activeChipId,
            scollDiv,
            scroll,
            abbreviateNumber,
            selectChip
        }
    }
})
