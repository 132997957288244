
import { useStore } from '@/store'
import { EventBuss, NUI_BET_TYPE } from '@/types/global'
import { isClicked } from '@/utils'
import {
    ChipItem,
    Emitter,
    PropType,
    TableData,
    computed,
    defineComponent,
    inject,
    ref
} from 'vue'

import BetBox2 from '@/components/bet/pc-deffault-bet-box-v2.vue'
import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import { AppMutation } from '@/store/types'
import { saveBet } from '@/api'

export default defineComponent({
    emits: ['on-update'],
    props: {
        mData: {
            type: Object as PropType<TableData>,
            require: false
        },
        chip: {
            type: Object as PropType<ChipItem>,
            require: false
        },
        win: {
            type: Array as PropType<NUI_BET_TYPE[]>,
            default: () => []
        }
    },
    components: {
        'bet-box-2': BetBox2
    },
    setup(props, { emit }) {
        const emitter = inject('emitter') as Emitter
        const store = useStore()

        const TEMP_P1_S_DOUBLE = ref(0)
        const TEMP_P1_DOUBLE = ref(0)
        const TEMP_P1_FLAT = ref(0)
        const TEMP_P2_S_DOUBLE = ref(0)
        const TEMP_P2_DOUBLE = ref(0)
        const TEMP_P2_FLAT = ref(0)
        const TEMP_P3_S_DOUBLE = ref(0)
        const TEMP_P3_DOUBLE = ref(0)
        const TEMP_P3_FLAT = ref(0)

        const P1_S_DOUBLE = ref(0)
        const P1_DOUBLE = ref(0)
        const P1_FLAT = ref(0)
        const P2_S_DOUBLE = ref(0)
        const P2_DOUBLE = ref(0)
        const P2_FLAT = ref(0)
        const P3_S_DOUBLE = ref(0)
        const P3_DOUBLE = ref(0)
        const P3_FLAT = ref(0)

        const P1_S_DOUBLE_BOX = ref()
        const P1_DOUBLE_BOX = ref()
        const P1_FLAT_BOX = ref()
        const P2_S_DOUBLE_BOX = ref()
        const P2_DOUBLE_BOX = ref()
        const P2_FLAT_BOX = ref()
        const P3_S_DOUBLE_BOX = ref()
        const P3_DOUBLE_BOX = ref()
        const P3_FLAT_BOX = ref()

        //#region Helpers

        const isBetIsSufficient = (amount: number) => {
            const total =
                TEMP_P1_S_DOUBLE.value +
                TEMP_P1_DOUBLE.value +
                TEMP_P1_FLAT.value +
                TEMP_P2_S_DOUBLE.value +
                TEMP_P2_DOUBLE.value +
                TEMP_P2_FLAT.value +
                TEMP_P3_S_DOUBLE.value +
                TEMP_P3_DOUBLE.value +
                TEMP_P3_FLAT.value +
                amount

            return coin.value >= total
        }

        const callUpdateCallback = () => {
            const hasTempBet =
                TEMP_P1_S_DOUBLE.value +
                    TEMP_P1_DOUBLE.value +
                    TEMP_P1_FLAT.value +
                    TEMP_P2_S_DOUBLE.value +
                    TEMP_P2_DOUBLE.value +
                    TEMP_P2_FLAT.value +
                    TEMP_P3_S_DOUBLE.value +
                    TEMP_P3_DOUBLE.value +
                    TEMP_P3_FLAT.value >
                0

            emit('on-update', hasTempBet)
        }
        const resetTempBet = () => {
            TEMP_P1_S_DOUBLE.value = 0
            TEMP_P1_DOUBLE.value = 0
            TEMP_P1_FLAT.value = 0
            TEMP_P2_S_DOUBLE.value = 0
            TEMP_P2_DOUBLE.value = 0
            TEMP_P2_FLAT.value = 0
            TEMP_P3_S_DOUBLE.value = 0
            TEMP_P3_DOUBLE.value = 0
            TEMP_P3_FLAT.value = 0

            // classic

            // dragon tiger
            if (P1_S_DOUBLE_BOX.value) P1_S_DOUBLE_BOX.value.removeTempUIBet()
            if (P2_S_DOUBLE_BOX.value) P2_S_DOUBLE_BOX.value.removeTempUIBet()
            if (P3_S_DOUBLE_BOX.value) P3_S_DOUBLE_BOX.value.removeTempUIBet()
            if (P1_DOUBLE_BOX.value) P1_DOUBLE_BOX.value.removeTempUIBet()
            if (P2_DOUBLE_BOX.value) P2_DOUBLE_BOX.value.removeTempUIBet()
            if (P3_DOUBLE_BOX.value) P3_DOUBLE_BOX.value.removeTempUIBet()
            if (P1_FLAT_BOX.value) P1_FLAT_BOX.value.removeTempUIBet()
            if (P2_FLAT_BOX.value) P2_FLAT_BOX.value.removeTempUIBet()
            if (P3_FLAT_BOX.value) P3_FLAT_BOX.value.removeTempUIBet()

            callUpdateCallback()
        }

        const resetBetUI = () => {
            if (props.mData) {
                const { id } = props.mData

                if (P1_S_DOUBLE_BOX.value && P1_S_DOUBLE.value > 0)
                    P1_S_DOUBLE_BOX.value.handleOnPlaceChip({
                        t: 'P1_S_DOUBLE',
                        amt: P1_S_DOUBLE.value,
                        type: 'confirmed',
                        deskId: id
                    })

                if (P2_S_DOUBLE_BOX.value && P2_S_DOUBLE.value > 0)
                    P2_S_DOUBLE_BOX.value.handleOnPlaceChip({
                        t: 'P2_S_DOUBLE',
                        amt: P2_S_DOUBLE.value,
                        type: 'confirmed',
                        deskId: id
                    })

                if (P3_S_DOUBLE_BOX.value && P3_S_DOUBLE.value > 0)
                    P3_S_DOUBLE_BOX.value.handleOnPlaceChip({
                        t: 'P3_S_DOUBLE',
                        amt: P3_S_DOUBLE.value,
                        type: 'confirmed',
                        deskId: id
                    })

                if (P1_DOUBLE_BOX.value && P1_DOUBLE.value > 0)
                    P1_DOUBLE_BOX.value.handleOnPlaceChip({
                        t: 'P1_DOUBLE',
                        amt: P1_DOUBLE.value,
                        type: 'confirmed',
                        deskId: id
                    })
                if (P2_DOUBLE_BOX.value && P2_DOUBLE.value > 0)
                    P2_DOUBLE_BOX.value.handleOnPlaceChip({
                        t: 'P2_DOUBLE',
                        amt: P2_DOUBLE.value,
                        type: 'confirmed',
                        deskId: id
                    })

                if (P3_DOUBLE_BOX.value && P3_DOUBLE.value > 0)
                    P3_DOUBLE_BOX.value.handleOnPlaceChip({
                        t: 'P3_DOUBLE',
                        amt: P3_DOUBLE.value,
                        type: 'confirmed',
                        deskId: id
                    })

                if (P1_FLAT_BOX.value && P1_FLAT.value > 0)
                    P1_FLAT_BOX.value.handleOnPlaceChip({
                        t: 'P1_FLAT',
                        amt: P1_FLAT.value,
                        type: 'confirmed',
                        deskId: id
                    })
                if (P2_FLAT_BOX.value && P2_FLAT.value > 0)
                    P2_FLAT_BOX.value.handleOnPlaceChip({
                        t: 'P2_FLAT',
                        amt: P2_FLAT.value,
                        type: 'confirmed',
                        deskId: id
                    })
                if (P3_FLAT_BOX.value && P3_FLAT.value > 0)
                    P3_FLAT_BOX.value.handleOnPlaceChip({
                        t: 'P3_FLAT',
                        amt: P3_FLAT.value,
                        type: 'confirmed',
                        deskId: id
                    })
            }
        }
        const applyTempBet = () => {
            TEMP_P1_S_DOUBLE.value = 0
            TEMP_P1_DOUBLE.value = 0
            TEMP_P1_FLAT.value = 0
            TEMP_P2_S_DOUBLE.value = 0
            TEMP_P2_DOUBLE.value = 0
            TEMP_P2_FLAT.value = 0
            TEMP_P3_S_DOUBLE.value = 0
            TEMP_P3_DOUBLE.value = 0
            TEMP_P3_FLAT.value = 0

            if (P1_S_DOUBLE_BOX.value) P1_S_DOUBLE_BOX.value.applyTempUIBet()
            if (P2_S_DOUBLE_BOX.value) P2_S_DOUBLE_BOX.value.applyTempUIBet()
            if (P3_S_DOUBLE_BOX.value) P3_S_DOUBLE_BOX.value.applyTempUIBet()
            if (P1_DOUBLE_BOX.value) P1_DOUBLE_BOX.value.applyTempUIBet()
            if (P2_DOUBLE_BOX.value) P2_DOUBLE_BOX.value.applyTempUIBet()
            if (P3_DOUBLE_BOX.value) P3_DOUBLE_BOX.value.applyTempUIBet()
            if (P1_FLAT_BOX.value) P1_FLAT_BOX.value.applyTempUIBet()
            if (P2_FLAT_BOX.value) P2_FLAT_BOX.value.applyTempUIBet()
            if (P3_FLAT_BOX.value) P3_FLAT_BOX.value.applyTempUIBet()

            callUpdateCallback()
        }

        const endGame = () => {
            if (P1_S_DOUBLE_BOX.value) P1_S_DOUBLE_BOX.value.resetBetUI()
            if (P2_S_DOUBLE_BOX.value) P2_S_DOUBLE_BOX.value.resetBetUI()
            if (P3_S_DOUBLE_BOX.value) P3_S_DOUBLE_BOX.value.resetBetUI()
            if (P1_DOUBLE_BOX.value) P1_DOUBLE_BOX.value.resetBetUI()
            if (P2_DOUBLE_BOX.value) P2_DOUBLE_BOX.value.resetBetUI()
            if (P3_DOUBLE_BOX.value) P3_DOUBLE_BOX.value.resetBetUI()
            if (P1_FLAT_BOX.value) P1_FLAT_BOX.value.resetBetUI()
            if (P2_FLAT_BOX.value) P2_FLAT_BOX.value.resetBetUI()
            if (P3_FLAT_BOX.value) P3_FLAT_BOX.value.resetBetUI()

            resetTempBet()
        }
        const confirmTempBet = () => {
            if (!props.mData) return
            let _params: any = {}
            const { expand, id } = props.mData

            _params = {
                memberId: memberId.value,
                tableId: id,
                shoeNo: expand.shoeNo,
                juNo: expand.juNo,
                gameNo: expand.gameNo,
                token: token.value,
                amount11: TEMP_P1_S_DOUBLE.value,
                amount12: TEMP_P1_DOUBLE.value,
                amount13: TEMP_P1_FLAT.value,
                amount21: TEMP_P2_S_DOUBLE.value,
                amount22: TEMP_P2_DOUBLE.value,
                amount23: TEMP_P2_FLAT.value,
                amount31: TEMP_P3_S_DOUBLE.value,
                amount32: TEMP_P3_DOUBLE.value,
                amount33: TEMP_P3_FLAT.value
            }

            store.commit(AppMutation.SHOW_LOADING)

            saveBet(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (response.data.status === 100) {
                        emitter.emit(EventBuss.SMALL_TOAST, {
                            msg: response.data.description,
                            id: props.mData?.id || 0
                        })

                        resetTempBet()
                    } else if (response.data.status === 200) {
                        emitter.emit(EventBuss.SMALL_TOAST, {
                            msg: '下注成功',
                            id: props.mData?.id || 0
                        })
                        audioPlayer.Play(SOUNDKEY.BET_SUCCESS)
                        applyTempBet()
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    resetTempBet()
                    emitter.emit(EventBuss.SMALL_TOAST, {
                        msg: '当前网络不佳，请重新尝试',
                        id: props.mData?.id || 0
                    })
                })
        }

        //#endregion

        //#region  Click Helpers
        const placeAbet = (e: MouseEvent, _betType: NUI_BET_TYPE) => {
            const boxIsClicked = isClicked(e, 0.3)

            if (boxIsClicked) {
                if (!props.mData) return

                const { mData, chip } = props

                if (mData.status !== 1) {
                    // countdown timer is done, no betting allowed
                    emitter.emit(EventBuss.SMALL_TOAST, {
                        msg: '当前不能下注',
                        id: props.mData?.id || 0
                    })
                    return
                }

                const _amount = chip?.value || 0

                if (_amount > 0) {
                    // check total temp bet is sufficient
                    if (!isBetIsSufficient(_amount)) {
                        emitter.emit(EventBuss.SMALL_TOAST, {
                            msg: ' 余额不足，投注失败！',
                            id: props.mData?.id || 0
                        })

                        return
                    }

                    switch (_betType) {
                        case 'P1_S_DOUBLE':
                            TEMP_P1_S_DOUBLE.value += _amount
                            break
                        case 'P1_DOUBLE':
                            TEMP_P1_DOUBLE.value += _amount
                            break
                        case 'P1_FLAT':
                            TEMP_P1_FLAT.value += _amount
                            break
                        case 'P2_S_DOUBLE':
                            TEMP_P2_S_DOUBLE.value += _amount
                            break
                        case 'P2_DOUBLE':
                            TEMP_P2_DOUBLE.value += _amount
                            break
                        case 'P2_FLAT':
                            TEMP_P2_FLAT.value += _amount
                            break
                        case 'P3_S_DOUBLE':
                            TEMP_P3_S_DOUBLE.value += _amount
                            break
                        case 'P3_DOUBLE':
                            TEMP_P3_DOUBLE.value += _amount
                            break
                        case 'P3_FLAT':
                            TEMP_P3_FLAT.value += _amount
                            break
                        default:
                            break
                    }

                    emitter.emit(EventBuss.PLACE_CHIP, {
                        t: _betType,
                        amt: _amount,
                        type: 'temp',
                        deskId: mData.id
                    })

                    audioPlayer.Play(SOUNDKEY.BET_PLACE)

                    callUpdateCallback()
                }
            }
        }
        //#endregion

        //#region Computed

        const memberId = computed(() => store.getters['memberid'])
        const token = computed(() => store.getters['token'])
        const coin = computed((): number => store.getters['coin'])

        //#endregion

        return {
            placeAbet,
            TEMP_P1_S_DOUBLE,
            TEMP_P1_DOUBLE,
            TEMP_P1_FLAT,
            TEMP_P2_S_DOUBLE,
            TEMP_P2_DOUBLE,
            TEMP_P2_FLAT,
            TEMP_P3_S_DOUBLE,
            TEMP_P3_DOUBLE,
            TEMP_P3_FLAT,
            P1_S_DOUBLE,
            P1_DOUBLE,
            P1_FLAT,
            P2_S_DOUBLE,
            P2_DOUBLE,
            P2_FLAT,
            P3_S_DOUBLE,
            P3_DOUBLE,
            P3_FLAT,
            P1_S_DOUBLE_BOX,
            P1_DOUBLE_BOX,
            P1_FLAT_BOX,
            P2_S_DOUBLE_BOX,
            P2_DOUBLE_BOX,
            P2_FLAT_BOX,
            P3_S_DOUBLE_BOX,
            P3_DOUBLE_BOX,
            P3_FLAT_BOX,
            resetTempBet,
            resetBetUI,
            applyTempBet,
            endGame,
            confirmTempBet
        }
    }
})
